<script>
import { BAvatar, BBadge } from 'bootstrap-vue'
export default {
  name: 'FormatCurrency',
  components: {
    BAvatar,
    BBadge
  },
  data () {
    return {
      defaultCurrency: 'BDT'
    }
  },
  props: {
    amount: Number,
    currency: {
      type: String,
    },
    type: {
      type: String,
      default: 'amount'
    },
  }
}
</script>
<template>
  <span>
    <template v-if="type === 'currency'">
      {{ currency ?? defaultCurrency }}
    </template>
      <template v-if="type === 'amount'">
        <b>
          {{ currency }} {{ amount?.toLocaleString()}}
        </b>
    </template>
  </span>
</template>

<style scoped lang="scss">

</style>
